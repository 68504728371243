import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import chroma from "chroma-js";
import React from "react";
import { Image, Linking, ScrollView, View } from "react-native";
import { useMediaQuery } from "react-responsive";
import Button from "../../components/common/Button";
import Footer from "../../components/common/Footer";
import StyledText from "../../components/common/StyledText";


const ReevaluationUefaPro = () => {


	const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })
	const navigation = useNavigation()

	const scale = chroma.scale(['#e4002b', '#8e051d']).colors(5);



	return (
		<>
			<ScrollView style={{ flex: 1, backgroundColor: "white" }} contentContainerStyle={{ flexGrow: 1 }}>
				<View style={{ flexDirection: "row", alignItems: "center", width: "100%", paddingTop: 0, alignSelf: "center" }}>
					<View style={{ flexDirection: "row", backgroundColor: "#06162D", width: "100%", alignItems: "center", justifyContent: 'center', paddingTop: 50, paddingHorizontal: 30, paddingBottom: 80 }}>
						<View style={{ width: "100%", maxWidth: 1200, alignItems: "center", justifyContent: "center", gap: 10, alignSelf: "center" }}>
							<StyledText style={{ fontFamily: "Title", fontSize: 60, color: "white", textAlign: "center" }}>Revaluación de su Licencia UEFA PRO</StyledText>
							<StyledText style={{ fontSize: 22, color: "white", textAlign: "center" }}>Si usted es poseedor de una licencia UEFA PRO que ha caducado o caducará el 31.12.2024,
								la RFEF ha habilitado dos formas de realizar la revaluación de esta licencia.
								Ambas opciones le permitirán completar las 15 horas exigidas por la UEFA para la revaluación.</StyledText>
						</View>
					</View>
				</View>

				<View style={{ maxWidth: 1200, width: "100%", zIndex: 3, alignSelf: "center", gap: 20, paddingVertical: 40, paddingHorizontal: 10 }}>
					<StyledText style={{ fontSize: 24, fontWeight: "bold", color: "black" }}>Elija su Opción de Revaluación</StyledText>
					<View style={{ flexDirection: isPhone ? "column" : "row", gap: 10, width: "100%", alignSelf: "center", marginBottom: 50 }}>
						<View style={{ flex: 1, backgroundColor: "white", padding: 20, borderRadius: 7, height: "auto", borderWidth: 2, borderColor: "#E4002B" }}>
							<View style={{ flexDirection: "row", alignItems: "center", width: "100%", paddingTop: 0, alignSelf: "center", gap: 10 }}>

								<StyledText style={{ fontSize: 24, fontFamily: "TitleWide", color: "black" }}>Presencial en la RFEF</StyledText>
							</View>
							<View style={{ flex: 1, paddingTop: 20, gap: 10 }}>
								<Image
									style={{ width: "100%", aspectRatio: 1.77, resizeMode: "contain", borderRadius: 15, marginBottom: 10 }}
									source={require("/assets/images/reevaluacion-presencial.jpeg")}
								/>
								<View style={{ flexDirection: "column", gap: 10 }}>
									<IconRow icon="calendar-outline" text="11 y 12 de octubre de 2024" />
									<IconRow icon="pin" text="Sede RFEF, Las Rozas de Madrid" />
									<IconRow icon="time-outline" text="Viernes: 15h - 20h, Sábado: 9h - 14h y 15h - 20h" />
								</View>
								<StyledText style={{ fontSize: 20, marginVertical: 15 }}>
									Complete las 15 horas requeridas en un entorno presencial con otros profesionales.
								</StyledText>
								<Button round={7} label="Preinscribirse" onPress={() => Linking.openURL("https://onformacion.rfef.es/fed/OF4VYpRfgUJEH5rG2syJ/curso/TyitImXZNNkSZjFISAWt")} />
							</View>
						</View>
						<View style={{ flex: 1, backgroundColor: "white", padding: 20, borderRadius: 7, height: "auto", borderWidth: 2, borderColor: "#E4002B" }}>
							<View style={{ flexDirection: "row", alignItems: "center", width: "100%", paddingTop: 0, alignSelf: "center", gap: 10 }}>

								<StyledText style={{ fontSize: 24, fontFamily: "TitleWide", color: "black" }}>Virtual Presencial</StyledText>

							</View>
							<View style={{ flex: 1, paddingTop: 20, gap: 10 }}>
								<Image
									style={{ width: "100%", aspectRatio: 1.77, resizeMode: "contain", borderRadius: 15, marginBottom: 10 }}
									source={require("/assets/images/reevaluacion-virtual.jpeg")}
								/>
								<View style={{ flexDirection: "column", gap: 10 }}>
									<IconRow icon="calendar-outline" text="Desde el 11 de octubre de 2024" />
									<IconRow icon="cloud-done-outline" text="30 horas de contenido (3 bloques de 10h)" />
									<IconRow icon="time-outline" text="Visualización flexible (mínimo 15h requeridas)" />
								</View>
								<StyledText style={{ fontSize: 20, marginVertical: 15 }}>
									Acceda a 30 horas de contenido y complete al menos 15 horas a su propio ritmo.
								</StyledText>
								<Button round={7} label="Preinscribirse" onPress={() => Linking.openURL("https://onformacion.rfef.es/fed/OF4VYpRfgUJEH5rG2syJ/curso/RKb0BZOPnBhN29uUF1zp")} />
							</View>
						</View>

					</View>
					<View >
						<StyledText style={{ fontSize: 24, marginBottom: 20, fontWeight: "bold", fontFamily: "TitleWide", color: "black" }}>Proceso de Renovación</StyledText>

						<StyledText style={{ fontSize: 20 }}>1 - Elija su opción preferida: presencial o virtual.</StyledText>
						<StyledText style={{ fontSize: 20 }}>2 - Complete las 15 horas requeridas de formación.</StyledText>
						<StyledText style={{ fontSize: 20 }}>3 - Acceda a su área personal en la plataforma de la RFEF.</StyledText>
						<StyledText style={{ fontSize: 20 }}>4 - Busque el botón "RENOVAR LICENCIA" en el apartado de licencias.</StyledText>
						<StyledText style={{ fontSize: 20 }}>5 - Realice el pago de 25€ para la renovación.</StyledText>
						<StyledText style={{ fontSize: 20 }}>6 - Su licencia se renovará automáticamente hasta el 31.12.2027.</StyledText>

					</View>
				</View>

				<Footer limited={false} />

			</ScrollView>
		</>
	);
};

export default ReevaluationUefaPro;


const IconRow = ({ icon, text }) => {
	return (
		<View style={{ flexDirection: "row", alignItems: "center", width: "100%", paddingTop: 0, alignSelf: "center", gap: 10 }}>
			<Ionicons name={icon} size={24} />
			<StyledText style={{ fontSize: 18, fontWeight: "bold", color: "black" }}>{text}</StyledText>
		</View>
	)
}
